import adapter from 'webrtc-adapter'

export const isSupported = () => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) return true
  const getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia
  if (!getUserMedia) return false
  return true
}

export const getConstrainst = () => {
  if (['firefox', 'safari'].includes(adapter.browserDetails.browser))
    return {
      audio: true,
      video: {
        width: 240,
        height: 240,
        facingMode: 'user'
      }
    }
  return {
    audio: { deviceId: { exact: 'default' } },
    video: {
      width: {
        min: 200,
        max: 240,
        ideal: 240
      },
      height: {
        min: 200,
        max: 240,
        ideal: 240
      },
      frameRate: {
        max: 15
      },
      facingMode: 'user'
    }
  }
}

const getLocalStream = constraints => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    return navigator.mediaDevices.getUserMedia(constraints)
  } else {
    const getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia
    if (!getUserMedia) return Promise.reject(new Error('Webcam not supported'))
    else
      return new Promise((resolve, reject) => {
        getUserMedia.call(navigator, constraints, resolve, reject)
      })
  }
}
export default getLocalStream
