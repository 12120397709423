const QUALITY = 0.7
export function compressImage(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = event => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')

        canvas.width = img.width
        canvas.height = img.height

        canvas
          .getContext('2d')
          .drawImage(img, 0, 0, canvas.width, canvas.height)

        canvas.toBlob(
          blob => resolve({ compressedFile: blob, image: event.target.result }),
          'image/jpeg',
          QUALITY
        )
      }
      img.onerror = reject
      img.src = event.target.result
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
