//@ts-check
import React, { useMemo } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { propEq } from 'ramda'
import { noop } from 'utils'
import { AttendanceTypes } from 'utils/constants'
import DarkClassfy from 'assets/logos/main/DarkClassfy'
import ChatIcon from 'assets/icons/ChatIcon'
import useClassroom from 'hooks/useClassroom'
import useConfirmNavigation from 'hooks/useConfirmNavigation'
import { HeaderProfile } from 'components/layout/Header'
import Button from 'components/buttons/Button'
import Modal from 'components/modals/Modal'
import { H3 } from 'components/typography'
import VideoSource from './VideoSource'
import WaitingRoom from './WaitingRoom'
import StudentVideoSource from './student/StudentVideoSource'
import StudentClassroom from './student/StudentClassroom'
import ClassroomReconnecting from './ClassroomReconnecting'
import EvalAdvisor from './EvalAdvisor'
import Chat from './Chat'
import config from 'config'
import styles from './Classroom.module.css'

const studentSortFunction =
  (studentLightbulbs, activeStudent) => (st1, st2) => {
    if (activeStudent === st1._id) return -1
    if (activeStudent === st2._id) return 1
    const light1 = studentLightbulbs.findIndex(light => light === st1._id)
    const light2 = studentLightbulbs.findIndex(light => light === st2._id)
    if (light1 === -1 && light2 === -1) return 0
    else if (light1 === -1) return 1
    else if (light2 === -1) return -1
    else return light1 - light2
  }

const sortByTime = timeByStudent => (st1, st2) => {
  const t1 = timeByStudent[st1._id] || 0
  const t2 = timeByStudent[st2._id] || 0
  return t2 - t1
}

const sortTests = (student1, student2) => {
  const isTest1 = student1.attendance?.type === AttendanceTypes.PRUEBA
  const isTest2 = student2.attendance?.type === AttendanceTypes.PRUEBA
  if (!isTest1 && !isTest2) return 0
  return isTest2 ? 1 : -1
}

function Classroom({}) {
  /**@type {import('hooks/useWebRTC').WebRTCState} */
  const {
    socket,
    identity: teacher,
    students,
    localStream,
    remoteStreams,
    isReconnecting,
    blockNavigation
  } = useOutletContext()
  const {
    activeStudent,
    selectedStudentChat,
    studentLightbulbs,
    lightbulbIdsByStudent,
    studentsReconnecting,
    timeByStudent,
    isDoubleColumn,
    messages,
    isChatModalOpen,
    changeActiveStudent,
    getStudentStream,
    handleSetTimerByStudent,
    clearStudentsReconnecting,
    openChatModal,
    closeChatModal,
    handleSendMessage,
    handleCleanNewMessage
  } = useClassroom({
    socket,
    students,
    remoteStreams
  })
  const { blocker } = useConfirmNavigation(blockNavigation)
  const studentsToEval = useMemo(
    () =>
      students.filter(
        ({ attendance = {} }) => attendance?.type === AttendanceTypes.PRUEBA
      ),
    [students]
  )

  const hasNewMessages =
    !!activeStudent && messages[activeStudent]?.some(({ isNew }) => !!isNew)
  return (
    <div
      className={[
        styles.container,
        isDoubleColumn ? styles.containerWitDoubleColumn : ''
      ].join(' ')}
    >
      <div className={styles.leftSide}>
        <Link to='/'>
          <DarkClassfy className={styles.classfyLogo} />
        </Link>
        {activeStudent && (
          <div className={styles.activeStudent}>
            <StudentVideoSource
              key={activeStudent}
              activeStudent={activeStudent}
              isDoubleColumn={isDoubleColumn}
              isReconnecting={studentsReconnecting.includes(activeStudent)}
              student={students.find(s => s._id === activeStudent)}
              stream={getStudentStream(activeStudent)}
              onClick={
                isReconnecting ? noop : changeActiveStudent(activeStudent)
              }
              onSetTimer={time => handleSetTimerByStudent(activeStudent, time)}
            />
          </div>
        )}
        <div
          className={[
            styles.studentList,
            isDoubleColumn ? styles.studentListDoubleColumn : ''
          ].join(' ')}
        >
          {students
            .sort(sortByTime(timeByStudent.current))
            .sort(studentSortFunction(studentLightbulbs, activeStudent))
            .sort(sortTests)
            .map(student => {
              const studentReconnecting = studentsReconnecting.includes(
                student._id
              )
              if (activeStudent !== student._id)
                return (
                  <StudentVideoSource
                    key={student._id}
                    activeStudent={activeStudent}
                    isDoubleColumn={isDoubleColumn}
                    lightbulbIdsByStudent={lightbulbIdsByStudent}
                    activeLightbulb={studentLightbulbs.includes(student._id)}
                    isReconnecting={studentReconnecting}
                    student={student}
                    stream={getStudentStream(student._id)}
                    showChatAlert={messages[student._id]?.some(
                      ({ isNew }) => !!isNew
                    )}
                    onClick={
                      isReconnecting || studentReconnecting
                        ? noop
                        : changeActiveStudent(student._id)
                    }
                    onOpenChat={() =>
                      isReconnecting || studentReconnecting
                        ? noop
                        : openChatModal(student._id)
                    }
                    onSetTimer={time =>
                      handleSetTimerByStudent(student._id, time)
                    }
                  />
                )
              return null
            })}
        </div>
      </div>
      <div className={styles.content}>
        {!activeStudent && (
          <div className={styles.space}>
            {isReconnecting ? <ClassroomReconnecting /> : <WaitingRoom />}
          </div>
        )}
        {students.map(student => (
          <StudentClassroom
            key={student._id}
            socket={socket}
            student={student}
            isActive={activeStudent === student._id}
            teacherIsReconnecting={isReconnecting}
          />
        ))}
      </div>

      <div className={styles.rightSide}>
        <HeaderProfile
          teacher={teacher}
          className={styles.headerProfile}
          useGreeting={false}
        />
        {!config.hide && studentsToEval && studentsToEval.length > 0 && (
          <EvalAdvisor students={studentsToEval} />
        )}
        <div className={styles.teacherVideo}>
          {activeStudent && (
            <div
              className={styles.containerChatIcon}
              onClick={() => openChatModal(activeStudent)}
            >
              {hasNewMessages && <div className={styles.chatMessageAlert} />}
              <ChatIcon
                color='var(--error-color)'
                className={styles.chatIcon}
              />
            </div>
          )}
          <VideoSource
            color={isReconnecting ? 'tertiary' : 'primary'}
            size='small'
            stream={localStream}
            disabled={isReconnecting}
            muted
          />
          {!!studentsReconnecting.length && (
            <div
              className={styles.reconnectingButton}
              title='Es recomendable esperar 15 segundos'
            >
              <Button
                size='medium'
                type='accent-secondary'
                label='Forzar reconexión'
                onClick={clearStudentsReconnecting}
              />
            </div>
          )}
        </div>
      </div>
      {blocker.state === 'blocked' && (
        <Modal showActions={false}>
          <div className={styles.confirmNavigationModal}>
            <H3>¿Seguro que quieres salir de clase?</H3>
            <div>
              <Button
                type='secondary'
                size='tiny'
                onClick={() => blocker.reset()}
              >
                No
              </Button>
              <Button
                type='warning'
                size='tiny'
                onClick={() => blocker.proceed()}
              >
                Sí
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isChatModalOpen && selectedStudentChat && (
        <Modal
          showActions={false}
          onCancel={closeChatModal}
          useFocus={false}
          showCloseIcon
        >
          <div className={styles.chatModalContainer}>
            <H3>
              {students.find(propEq('_id', selectedStudentChat))?.name ||
                'Sin alumno'}
              {studentsReconnecting.includes(selectedStudentChat) &&
                ' - Reconectando...'}
            </H3>
            <Chat
              messages={messages[selectedStudentChat] || []}
              onSend={handleSendMessage}
              onCleanAlert={handleCleanNewMessage}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}

export default Classroom
