import React, { useCallback, useEffect, useRef, useState } from 'react'
import { noop } from 'utils'
import { AttendanceTypes } from 'utils/constants'
import BrainOn from 'assets/icons/BrainOn'
import Doubt from 'assets/icons/Doubt'
import Learning from 'assets/icons/Learning'
import Understood from 'assets/icons/Understood'
import ChatIcon from 'assets/icons/ChatIcon'
import { Paragraph } from 'components/typography'
import DotsSpinner from 'components/spinners/DotsSpinner'
import VideoSource from '../VideoSource'
import Timer from '../Timer'
import ClassroomLightbulb from '../Lightbulb'
import audio from './resources/sound.mp3'
import styles from './StudentVideoSource.module.css'

export const LightbulbIds = {
  lightbulb: 'lightbulb',
  learning: 'learning',
  doubt: 'doubt',
  brainOn: 'brainOn',
  understood: 'understood'
}

export const TooltipTextByLightbulbId = {
  lightbulb: 'Help ☝️!',
  learning: 'Aprendiendo 😢',
  doubt: 'Tengo una duda 🤔',
  brainOn: '🧠 ON',
  understood: 'Entendido 🤙🏻'
}

const CHAT_ID = 'chatId'

const LightbulbIconByType = {
  [LightbulbIds.lightbulb]: (
    <ClassroomLightbulb
      classNames={{
        container: styles.lightbulbContainer,
        innerContainer: styles.lightbulbInnerContainer
      }}
    />
  ),
  [LightbulbIds.learning]: (
    <div className={styles.wrapperIcon}>
      <Learning className={styles.icon} />
    </div>
  ),
  [LightbulbIds.doubt]: (
    <div className={styles.wrapperIcon}>
      <Doubt className={styles.icon} />
    </div>
  ),
  [LightbulbIds.brainOn]: (
    <div className={styles.wrapperIcon}>
      <BrainOn className={styles.icon} />
    </div>
  ),
  [LightbulbIds.understood]: (
    <div className={styles.wrapperIcon}>
      <Understood className={styles.icon} />
    </div>
  )
}

function StudentVideoSource({
  student = {},
  lightbulbIdsByStudent = {},
  activeStudent = '',
  isDoubleColumn = false,
  activeLightbulb = false,
  isReconnecting = false,
  showChatAlert = false,
  stream,
  onClick = noop,
  onOpenChat = noop,
  onSetTimer = noop
}) {
  const tooltipRef = useRef(null)
  const tooltip = tooltipRef.current
  const parentRef = useRef(null)
  const parent = parentRef.current
  const [timer, setTimer] = useState({ idle: 0, active: false })
  const [lightbulbTimer, setLightbulbTimer] = useState({
    idle: 0,
    active: false
  })
  const isStudentActive = activeStudent === student._id
  const isTest = student.attendance?.type === AttendanceTypes.PRUEBA
  const studentLightbulbId = lightbulbIdsByStudent[student._id]

  const handleClick = e => {
    if (CHAT_ID == e.target.id) return onOpenChat()
    onClick()
  }
  const parentBackgroundColor = isTest
    ? 'var(--sandground-50)'
    : 'var(--seadapted-50)'
  const lateralTooltipBackgroundColor = isTest
    ? 'var(--sandground)'
    : 'var(--seadapted)'

  const displayTooltip = () => {
    if (!tooltip || !parent) return
    parent.style.setProperty('--parent-color', parentBackgroundColor)

    const tooltipMargin = 10
    const parentRect = parent.getBoundingClientRect()
    parent.style.setProperty('--tooltip-color', lateralTooltipBackgroundColor)

    tooltip.style.display = 'inline-flex'
    const tooltipRect = tooltip.getBoundingClientRect()
    tooltip.style.left = parentRect.x + parentRect.width + tooltipMargin + 'px'

    const offset =
      parentRect.height > tooltipRect.height
        ? Math.abs((tooltipRect.height - parentRect.height) / 2)
        : -(tooltipRect.height - parentRect.height) / 2

    tooltip.style.top = parentRect.y + offset + 'px'
    tooltip.style.position = 'fixed'
  }

  const hideTooltip = useCallback(() => {
    if (tooltip) tooltip.style.display = 'none'
  }, [tooltip])

  useEffect(() => {
    if (isStudentActive) setTimer({ idle: 0, active: false })
    else setTimer({ idle: 0, active: true })
  }, [isStudentActive])

  useEffect(() => {
    if (activeLightbulb) setLightbulbTimer({ idle: 0, active: true })
    if (isStudentActive) setLightbulbTimer({ idle: 0, active: false })
    else setLightbulbTimer({ idle: 0, active: true })
  }, [activeLightbulb, isStudentActive])

  useEffect(() => {
    if (!activeLightbulb) return
    let sound = new Audio(audio)
    sound.play()
  }, [activeLightbulb])

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer(current =>
        current.active ? { idle: current.idle + 1, active: true } : current
      )
    }, 1000)
    return () => clearInterval(timerInterval)
  }, [])

  useEffect(() => {
    const timerBInterval = setInterval(() => {
      setLightbulbTimer(prevTimerB =>
        prevTimerB.active
          ? {
              idle: prevTimerB.idle + 1,
              active: true
            }
          : prevTimerB
      )
    }, 1000)

    return () => clearInterval(timerBInterval)
  }, [])

  useEffect(() => {
    onSetTimer(timer.idle)
  }, [onSetTimer, timer])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        hideTooltip()
      },
      { threshold: 0.1 }
    )

    if (parent) {
      observer.observe(parent)
    }

    return () => {
      if (parent) {
        observer.unobserve(parent)
      }
    }
  }, [hideTooltip, parent])
  return (
    <div
      ref={parentRef}
      className={[
        styles.container,
        isReconnecting ? styles.reconnecting : ''
      ].join(' ')}
      onClick={handleClick}
      onMouseEnter={e => {
        displayTooltip(e)
        displayTooltip(e)
      }}
      onMouseLeave={hideTooltip}
    >
      <div className={styles.timer}>
        <Timer seconds={timer.idle} active={isStudentActive} />
      </div>
      <VideoSource
        color={isReconnecting ? 'error' : isTest ? 'tertiary' : 'secondary'}
        stream={stream}
        size={isStudentActive ? 'large' : isDoubleColumn ? 'small' : 'medium'}
        disabled={!isStudentActive}
        muted={!isStudentActive}
      />
      {activeLightbulb && (
        <>
          {LightbulbIconByType[studentLightbulbId]}
          <div className={styles.lightbulbTimer}>
            <Timer
              seconds={lightbulbTimer.idle}
              active={isStudentActive}
              color='var(--success-color)'
              size='small'
            />
          </div>
        </>
      )}
      <div className={styles.innerBackground}>
        {isReconnecting && <DotsSpinner />}
      </div>
      {showChatAlert && (
        <div
          id={CHAT_ID}
          className={styles.containerChatIcon}
          onClick={handleClick}
        >
          <ChatIcon
            id={CHAT_ID}
            color='var(--error-color-50)'
            className={styles.chatIcon}
          />
        </div>
      )}
      <div ref={tooltipRef} className={styles.lateralTooltip}>
        <Paragraph type='body1Bold' className={styles.student}>
          {isTest && (
            <>
              ¡¡¡NUEVO PADAWAN!!!
              <br />
            </>
          )}
          {student.name}
          <br />
          {getAttendanceInfo(student.attendance, studentLightbulbId)}
        </Paragraph>
      </div>
    </div>
  )
}

export default StudentVideoSource

function getAttendanceInfo(attendance, lightbulbId = LightbulbIds.brainOn) {
  if (!attendance) return null
  let text =
    TooltipTextByLightbulbId[lightbulbId] || TooltipTextByLightbulbId.brainOn
  if (attendance?.subjectName) text += ' - ' + attendance.subjectName
  if (attendance?.subjectLevel) text += ' de ' + attendance.subjectLevel
  if (attendance?.subjectSublevel)
    text += ' (' + attendance.subjectSublevel + ')'
  return text
}
