//@ts-check
import { useCallback, useEffect, useState } from 'react'
import { assoc, assocPath, lensPath, set } from 'ramda'
import { classroomTabIds } from 'components/academy/classroom/student/Tabs'
import { classroomToolbarIds } from 'components/academy/classroom/Toolbar'
import {
  hangUpCall,
  sendVideoRemove,
  sendVideoSelect,
  sendSelectedTabChange
} from 'api/sockets'

const initialSelectedToolState = {
  [classroomTabIds.blackboard]: classroomToolbarIds.draw,
  [classroomTabIds.notebook]: classroomToolbarIds.draw,
  [classroomTabIds.photo]: classroomToolbarIds.draw,
  [classroomTabIds.video]: classroomToolbarIds.draw
}

const initialZoomState = {
  [classroomTabIds.blackboard]: 0,
  [classroomTabIds.notebook]: 0,
  [classroomTabIds.photo]: 0,
  [classroomTabIds.video]: 0
}

const initialCanvasState = {
  [classroomTabIds.blackboard]: [],
  [classroomTabIds.notebook]: [],
  [classroomTabIds.photo]: [],
  [classroomTabIds.video]: []
}

const initialState = {
  selectedTabByStudent: classroomTabIds.blackboard,
  selectedTab: classroomTabIds.blackboard,
  toolBySection: initialSelectedToolState,
  zoomBySection: initialZoomState,
  canvasBySection: initialCanvasState,
  selectedNotebookCategory: '',
  learningVideo: ''
}

export default function useStudentClassroom({
  socket,
  student,
  isActive = false,
  teacherIsReconnecting = false
}) {
  const [state, setState] = useState(initialState)
  const {
    selectedTabByStudent,
    selectedTab,
    toolBySection,
    zoomBySection,
    canvasBySection,
    learningVideo,
    selectedNotebookCategory
  } = state

  const handleTabChange = tabId => {
    setState(assoc('selectedTab', tabId))
    sendSelectedTabChange({ socket, tabId, student }).catch()
  }

  const handleToolChange = toolId =>
    setState(assocPath(['toolBySection', selectedTab], toolId))

  const handleZoomChange = value =>
    setState(assocPath(['zoomBySection', selectedTab], value))

  const handleSaveCanvasContext = useCallback((partialContext, tab) => {
    const tabLens = lensPath(['canvasBySection', tab])
    setState(state => set(tabLens, partialContext, state))
  }, [])
  const handleClearCanvasContext = useCallback(tab => {
    const tabLens = lensPath(['canvasBySection', tab])
    setState(set(tabLens, []))
  }, [])

  const handleLearningVideoChange = useCallback(
    (learningVideo = '') => {
      if (learningVideo)
        sendVideoSelect({ socket, student, url: learningVideo })
      else sendVideoRemove({ socket, student })
      setState(assoc('learningVideo', learningVideo))
    },
    [socket, student]
  )

  const handleHangUpCall = useCallback(() => {
    hangUpCall({ socket, student })
  }, [socket, student])

  const handleNotebookCategory = useCallback(
    selectedNotebookCategory =>
      setState(assoc('selectedNotebookCategory', selectedNotebookCategory)),
    []
  )
  useEffect(() => {
    if (teacherIsReconnecting)
      setState(assoc('selectedTab', classroomTabIds.none))
  }, [teacherIsReconnecting])

  useEffect(() => {
    if (!socket) return
    socket.on('classroom:students:selected-tab', ({ studentId, tabId }) => {
      if (studentId !== student) return
      setState(assoc('selectedTabByStudent', tabId))
    })
    return () => {
      if (!socket) return
      socket.off('classroom:students:selected-tab')
    }
  }, [socket, student])

  return {
    isActive,
    selectedTabByStudent,
    selectedTab,
    selectedTool: toolBySection[selectedTab],
    zoom: zoomBySection[selectedTab],
    canvasBySection,
    learningVideo,
    selectedNotebookCategory,
    handleTabChange,
    handleToolChange,
    handleZoomChange,
    handleSaveCanvasContext,
    handleClearCanvasContext,
    handleLearningVideoChange,
    handleHangUpCall,
    handleNotebookCategory
  }
}
